<template>
  <LhvModal/>
  <CartModal/>
  <AppToast/>
  <div class="drawer">
    <input id="sidebar" type="checkbox" class="drawer-toggle" :checked="sidebar"/>
    <SideBar/>
    <div class="drawer-content">
      <HeaderDesktop/>
      <HeaderMobile/>
      <slot/>
      <AppFooter/>
    </div>
  </div>
</template>

<script setup>
import "../css/app.css";
import SideBar from "./composables/SideBar.vue";
import AppFooter from "./composables/AppFooter.vue";
import HeaderMobile from "./composables/HeaderMobile.vue";
import HeaderDesktop from "./composables/HeaderDesktop.vue";
import AppToast from "./components/AppToast.vue";
import {router} from "@inertiajs/vue3";
import LhvModal from "./composables/LhvModal.vue";
import {useStore} from "./useStore.js";
import {storeToRefs} from "pinia";
import CartModal from "./composables/CartModal.vue";
import {onMounted} from "vue";

const store = useStore();
const {sidebar} = storeToRefs(store);

onMounted(() => {
  window.inertiaEventsCount = {
    navigateCount: 0,
    successCount: 0,
    errorCount: 0
  };
  router.on("navigate", () => {
    window.inertiaEventsCount.navigateCount++;
  });

  router.on("success", () => {
    window.inertiaEventsCount.successCount++;
  });

  router.on("error", () => {
    window.inertiaEventsCount.errorCount++;
  });
})
</script>
