<template>
  <dialog v-if="page.props.cartModalData" class="modal" :class="{'modal-open': isOpen}">
    <div ref="modal" class="w-11/12 modal-box max-w-3xl">
      <form method="dialog">
        <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
          <XMarkIcon class="w-4 h-4" @click="closeModal"/>
        </button>
      </form>
      <h3 class="font-semibold text-2xl pb-4">{{ __("Toode on lisatud ostukorvi!") }}</h3>
      <div class="description w-full overflow-y-hidden">
        <div class="flex flex-col gap-4 lg:flex-row w-full">
          <ProductPicture
              classes="w-40"
              :image="product.image && product.image.filename"
              :title="product.title"
              size="235"
          />
          <div class="grow">
            <Link
                class="text-lg font-semibold"
                :href=" trRoute('product', { product: trField(product, 'url') })">
              {{ trField(product, "title") }}
            </Link>
            <div class="flex flex-col">
              <p class="py-2">{{ product.code }}</p>
              <div class="pb-2">
                <ProductShipping
                    :stocklevel="product.stocklevel"
                    :interim-stocklevel="product.interim_stocklevel"
                    :shipping-time="product.shipping_time"
                    :hint="false"
                />
              </div>
              <ProductPrice
                  :price-vat="product.price_vat"
                  :price-default-vat="product.price_default_vat"
                  :discount-start="product.discount_start"
                  :discount-end="product.discount_end"
                  :good-price="product.good_price"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-action flex">
        <Link :href="trRoute('cart')" class="btn btn-success text-white">
          {{ __("Vormista tellimus") }}
        </Link>
      </div>
    </div>
  </dialog>
</template>
<script setup>
import {Link, usePage} from "@inertiajs/vue3";
import {__, trField, trRoute} from "../utils.js";
import {computed, ref, watch} from "vue";
import {isObject, onClickOutside} from "@vueuse/core";
import ProductPicture from "../components/ProductPicture.vue";
import ProductShipping from "../components/ProductShipping.vue";
import ProductPrice from "../components/ProductPrice.vue";
import {XMarkIcon} from "@heroicons/vue/24/solid/index.js";

const modal = ref(null);
onClickOutside(modal, () => isOpen.value = false);
const page = usePage();
const product = computed(() => page.props.cartModalData?.product);
const isOpen = ref(true);
const closeModal = () => isOpen.value = false;
watch(() => page.props.cartModalData, (cartModalData) => {
  if (isObject(cartModalData) && cartModalData.nonce) {
    isOpen.value = true;
  }
});
</script>