<template>
  <div
      class="hidden print:hidden lg:flex container max-w-screen-1.5xl mx-auto py-4 space-x-3 px-4 lg:px-8 flex-row justify-between">
    <Link :href="trRoute('home')">
      <LogoSvg class="w-32"/>
    </Link>
    <AppSearch
        v-if="!isCheckout(currentRoute)"
        :action="trRoute('search.json')"
        :placeholder="__('Otsi koduseadmeid või -tehnikat')"
    />
    <div class="flex items-center">
      <AccountDesktop/>
      <LangDesktop/>
      <CartItems/>
    </div>
  </div>
  <MenuDesktop v-if="!isCheckout(currentRoute)"/>
  <hr class="print:hidden hidden lg:block bg-secondary-300 h-px border-0"/>
  <div
      class="print:hidden py-2 hidden text-base lg:block text-primary-500 container mx-auto max-w-screen-1.5xl px-4 lg:px-8">
    <ProductArguments v-if="!status"/>
  </div>
</template>

<script setup>
import LogoSvg from "../../images/logo.svg?component";
import {__, isCheckout, trRoute} from "../utils";
import AppSearch from "./AppSearch.vue";
import LangDesktop from "../components/LangDesktop.vue";
import {Link, usePage} from "@inertiajs/vue3";
import CartItems from "../components/CartItems.vue";
import MenuDesktop from "./MenuDesktop.vue";
import {route} from "ziggy-js";
import AccountDesktop from "./AccountDesktop.vue";
import ProductArguments from "./ProductArguments.vue";

const currentRoute = route().current();
const status = usePage().props.status;
</script>
