<template>
  <dialog v-if="page.props.lhvModalData" :open="isOpen" class="modal" :class="{'modal-open': isOpen}">
    <div class="w-11/12 modal-box max-w-5xl">
      <h3 class="font-semibold text-lg">{{ page.props.lhvModalData.title }}</h3>
      <div
          class="description w-full overflow-y-hidden prose-sm prose max-w-none"
          v-html="page.props.lhvModalData.content"></div>
      <div class="modal-action">
        <div class="inline-block p-4">{{ __("Nõustun teenuse tingimustega") }}</div>
        <button class="btn btn-secondary btn-outline" @click="removeProduct">Ei</button>
        <button class="btn btn-success text-white" @click="closeModal">Jah</button>
      </div>
    </div>
  </dialog>
</template>
<script setup>
import {router, usePage} from "@inertiajs/vue3";
import {__, trRoute} from "../utils.js";
import {ref, watch} from "vue";
import {isObject} from "@vueuse/core";

const page = usePage();
const isOpen = ref(true);
const closeModal = () => isOpen.value = false;
const removeProduct = () => router.post(trRoute("cart"), {
  id: page.props.lhvModalData.productId,
  row_product_id: page.props.lhvModalData.rowProductId,
  checked: false,
  hide_notification: true
}, {
  onSuccess: () => closeModal()
});
watch(() => page.props.lhvModalData, (lhvModalData) => {
  if (isObject(lhvModalData) && lhvModalData.nonce) {
    isOpen.value = true;
  }
});
</script>