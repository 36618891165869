<template>
  <div class="print:hidden flex lg:hidden flex-row m-4 justify-between items-center">
    <label for="sidebar" aria-label="open sidebar" class="toggle-sidebar" @click.prevent="toggleSidebar">
      <Bars3CenterLeftIcon class="block w-8 h-8"/>
    </label>
    <Link :href="trRoute('home')" class="center">
      <LogoSvg class="w-24"/>
    </Link>
    <div>
      <AccountMobile/>
      <CartItems/>
    </div>
  </div>
  <div class="print:hidden block lg:hidden px-4">
    <AppSearch
        :action="trRoute('search.json')"
        :placeholder="__('Otsi koduseadmeid või -tehnikat')"
    />
  </div>
  <div class="print:hidden block lg:hidden p-2 text-base text-primary-500 text-center">
    <ProductArguments v-if="!status" :mobile="true"/>
  </div>
</template>

<script setup>
import {Bars3CenterLeftIcon} from "@heroicons/vue/24/solid";
import CartItems from "../components/CartItems.vue";
import AppSearch from "./AppSearch.vue";
import {__, trRoute} from "../utils";
import {Link, usePage} from "@inertiajs/vue3";
import LogoSvg from "../../images/logo.svg";
import {useStore} from "../useStore.js";
import AccountMobile from "./AccountMobile.vue";
import ProductArguments from "./ProductArguments.vue";

const store = useStore();
const {toggleSidebar} = store;
const status = usePage().props.status;
</script>
