<template>
  <div class="inline-block align-top mr-4 mt-2.5" @click.prevent="toggleSidebar">
    <div class="inline-block">
      <span v-if="user && user.b2c_client" class="inline-block -mt-4 text-xs font-semibold mr-1">
           {{ user.name }}
          <template v-if="user.b2c_client.client_type === 'company'">
            <br/>{{ user.b2c_client.name }}
          </template>
        </span>
      <AccountSvg class="inline-block -mt-4"/>
    </div>
  </div>
</template>

<script setup>
import AccountSvg from "../../images/elux-icon-account.svg";
import {usePage} from "@inertiajs/vue3";
import {computed} from "vue";
import {useStore} from "../useStore.js";

const page = usePage();
const user = computed(() => page.props.user);
const store = useStore();
const {toggleSidebar} = store;
</script>