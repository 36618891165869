import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32.739",
  height: "26.545"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "none",
      stroke: "#001e41",
      "stroke-width": "1.5",
      transform: "translate(-1295.415 -27.205)"
    }, [
      _createElementVNode("path", { d: "m1300.201 32.411-4.786-4.457h3.431l4.786 18.18h18.3l5.142-13.724h-26.873" }),
      _createElementVNode("circle", {
        cx: "2.5",
        cy: "2.5",
        r: "2.5",
        "data-name": "Ellipse 6",
        transform: "translate(1303 48)"
      }),
      _createElementVNode("circle", {
        cx: "2.5",
        cy: "2.5",
        r: "2.5",
        "data-name": "Ellipse 7",
        transform: "translate(1318 48)"
      })
    ], -1)
  ])))
}
export default { render: render }