<template>
  <footer class="print:hidden bg-base-200 text-primary-500">
    <div class="container max-w-screen-1.5xl grid grid-cols-2 px-4 lg:px-8 lg:grid-cols-6 gap-8 mx-auto py-5">
      <div class="flex flex-col items-center col-span-2 lg:col-span-1 lg:items-start">
        <LogoSvg class="w-24 mb-4"/>
        <div>{{ __("Kvaliteetsed koduseadmed alates 1997. aastast") }}</div>
        <CreditInfoEstSvg v-if="getActiveLanguage() === 'et'" class="mt-5 h-40"/>
        <CreditInfoRusSvg v-else class="mt-5 h-40"/>
      </div>
      <div class="flex flex-col items-start">
        <Link class="font-bold pb-4 block" :href="trRoute('contact')">
          {{ __("Kontakt") }}
        </Link>
        <div class="prose prose-sm text-primary-500" v-html="footer"></div>
        <div class="flex lg:hidden flex-col items-start">
          <Link class="font-bold pb-4 block" :href="trRoute('contact')">
            {{ __("Klienditugi") }}
          </Link>
          <div class="prose prose-sm text-primary-500" v-html="footer2"></div>
        </div>
      </div>
      <div class="hidden lg:flex flex-col items-start">
        <Link class="font-bold pb-4 block" :href="trRoute('contact')">
          {{ __("Klienditugi") }}
        </Link>
        <div class="prose prose-sm text-primary-500" v-html="footer2"></div>
      </div>
      <div class="flex flex-col items-center lg:items-start">
        <h4 class="font-bold pb-4">{{ __("Kasulikku") }}</h4>
        <Link class="block pb-4" :href="trRoute('news')">
          {{ __("Uudised") }}
        </Link>
        <Link class="block pb-4" :href="trRoute('contact')">
          {{ __("Kontakt") }}
        </Link>
        <Link
            v-for="(title, url) in helpPages"
            :key="url"
            class="block pb-4"
            :href="trRoute('page', { page: url })"
        >
          {{ title }}
        </Link>
      </div>
      <div class="flex flex-col items-center lg:items-start col-span-2">
        <div class="pb-8">
          <h4 class="font-bold pb-4">{{ __("Liitu uudiskirjaga") }}</h4>
          <div class="pb-4">
            {{ __("Uudiskirjaga saadame Sulle vaid kõige paremaid koduseadmete ja -tehnika pakkumisi.") }}
          </div>
          <div class="join w-full pb-4">
            <input
                v-model="form.email"
                class="input w-9/12 input-sm input-bordered join-item"
                :placeholder="__('Sisesta oma e-posti aadress')"/>
            <button class="btn btn-sm btn-primary join-item" @click="submitForm">{{ __('Liitu') }}</button>
          </div>
        </div>
        <h4 class="font-bold pb-4">{{ __("Jälgi meid sotsiaalmeedias") }}</h4>
        <div class="flex flex-row gap-x-20">
          <a href="https://www.facebook.com/eluxkodutehnika/" class="leading-6 align-middle" target="_blank">
            <FBSvg class="w-6 h-6 mr-2 inline-block"/>
            Facebook
          </a>
          <a href="https://www.instagram.com/elux.kodutehnika" class="leading-6 align-middle" target="_blank">
            <IGSvg class="w-6 h-6 mr-2 inline-block"/>
            Instagram
          </a>
        </div>
      </div>
    </div>
    <div class="container mx-auto py-8 px-5 font-normal text-center lg:text-left">
      <div class="divider"></div>
      {{ __("Autoriõigused ©:y E-Lux Kodutehnika OÜ. Kõik õigused kaitstud.", {y}) }}
    </div>
  </footer>
</template>

<script setup>
import LogoSvg from "../../images/logo.svg?component";
import {__, trRoute} from "../utils";
import {Link, useForm, usePage} from "@inertiajs/vue3";
import {computed} from "vue";
import {getActiveLanguage} from "laravel-vue-i18n";
import FBSvg from "../../images/facebook.svg?component";
import IGSvg from "../../images/instagram.svg?component";
import CreditInfoEstSvg from "../../images/creditinfo_et.svg?component";
import CreditInfoRusSvg from "../../images/creditinfo_ru.svg?component";

const form = useForm({
  email: null,
});
const submitForm = () => form.post(trRoute("newsletter"), {
  onSuccess: () => form.reset("email"),
  preserveScroll: true
});
const y = new Date().getFullYear();
const page = usePage();
const footer = computed(() => page.props.footer);
const footer2 = computed(() => page.props.footer2);
const helpPages = computed(() => page.props.helpPages);
</script>
