<template>
  <Link :href="trRoute('cart')" class="indicator">
    <span class="indicator-item badge badge-secondary text-white w-6 h-6 rounded-full">{{ cartItems }}</span>
    <CartSvg class="inline-block"/>
  </Link>
</template>

<script setup>
import {trRoute} from "../utils";
import {Link, usePage} from "@inertiajs/vue3";
import {computed} from "vue";
import CartSvg from "../../images/elux-icon-cart.svg?component";

const page = usePage();
const cartItems = computed(() => page.props.cartItems);
</script>
