<template>
  <div
      class="inline-block dropdown align-top mr-2 mt-3 account-desktop"
      :class="{'dropdown-open': visible}"
      @mouseenter="showMenu"
      @click="toggleMenu"
      @mouseleave="hideMenu">
    <div class="inline-block">
      <span v-if="user && user.b2c_client" class="inline-block -mt-4 text-xs font-semibold mr-1">
           {{ user.name }}
          <template v-if="user.b2c_client.client_type === 'company'">
            <br/>{{ user.b2c_client.name }}
          </template>
        </span>
      <AccountSvg class="inline-block -mt-4"/>
    </div>
    <ul
        class="dropdown-content p-2 space-y-2 shadow border border-gray-300 menu z-[2] bg-base-100 right-0 rounded w-52">
      <template v-if="!user || !user.b2c_client">
        <li>
          <div class="btn btn-ghost hover:bg-white cursor-default no-animation">
            {{ __("Tere tulemast E-Luxi!") }}
          </div>
        </li>
        <li>
          <Link
              class="btn btn-primary"
              :href="trRoute('login')"
          >
            {{ __("Logi sisse") }}
          </Link>
        </li>
        <li></li>
        <li>
          <div class="btn btn-ghost hover:bg-white cursor-default no-animation">
            {{ __("Sul pole kontot?") }}
          </div>
        </li>
        <li>
          <Link
              class="btn btn-primary btn-outline"
              :href="trRoute('register')"
          >
            {{ __("Registreeri") }}
          </Link>
        </li>
        <li></li>
      </template>
      <li>
        <Link class="btn orders" :href="trRoute('orders.index')">
          {{ __("Tellimused") }}
        </Link>
      </li>
      <li>
        <Link class="btn settings" :href="trRoute('settings')">
          {{ __("Seaded") }}
        </Link>
      </li>
      <li>
        <Link
            v-if="user && user.b2c_client"
            class="btn btn-error btn-outline desktop-logout-btn"
            as="button"
            type="button"
            method="post"
            :href="trRoute('logout')"
        >
          {{ __("Välju") }}
        </Link>
      </li>
      <li>
        <a class="btn btn-outline btn-neutral" :href="B2B_URL">
          {{ __("Ärikliendile") }}
          <ChevronRightIcon class="h-4 w-4"/>
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup>
import AccountSvg from "../../images/elux-icon-account.svg";
import {ChevronRightIcon} from "@heroicons/vue/24/solid";
import {Link, usePage} from "@inertiajs/vue3";
import {computed, ref} from "vue";
import {__, trRoute} from "../utils.js";

const page = usePage();
const user = computed(() => page.props.user);
const B2B_URL = import.meta.env.VITE_B2B_URL;
const visible = ref(false);
const toggleMenu = () => visible.value = !visible.value;
const hideMenu = () => visible.value = false;
const showMenu = () => visible.value = true;
</script>