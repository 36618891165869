import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#001e41",
      d: "M14.829 6.3c-.738-.034-.96-.04-2.829-.04s-2.09.007-2.828.04A2.63 2.63 0 0 0 6.3 9.172c-.033.738-.041.959-.041 2.828s.008 2.09.041 2.829a2.63 2.63 0 0 0 2.87 2.87c.737.033.959.041 2.828.041s2.091-.007 2.829-.041a2.63 2.63 0 0 0 2.87-2.87c.033-.738.04-.96.04-2.829s-.007-2.09-.04-2.828a2.632 2.632 0 0 0-2.87-2.87ZM12 15.6a3.595 3.595 0 1 1 3.6-3.6 3.595 3.595 0 0 1-3.6 3.6m3.737-6.5a.84.84 0 1 1 .84-.84.84.84 0 0 1-.84.84m-1.4 2.9A2.333 2.333 0 1 1 12 9.667 2.333 2.333 0 0 1 14.333 12ZM12 0a12 12 0 1 0 12 12A12 12 0 0 0 12 0m6.958 14.886a3.864 3.864 0 0 1-4.071 4.072C14.14 18.992 13.9 19 12 19s-2.139-.008-2.886-.042a3.865 3.865 0 0 1-4.072-4.072C5.008 14.14 5 13.9 5 12s.008-2.139.042-2.886a3.863 3.863 0 0 1 4.072-4.071C9.861 5.008 10.1 5 12 5s2.14.008 2.887.043a3.863 3.863 0 0 1 4.071 4.071c.034.747.042.986.042 2.886s-.008 2.14-.042 2.886"
    }, null, -1)
  ])))
}
export default { render: render }