<template>
  <Teleport to="body">
    <div
        v-show="$page.props.message || $page.props.error || $page.props.warning"
        class="whitespace-normal toast toast-top toast-end z-50"
    >
      <div
          class="alert text-white"
          :class="{
        'alert-success': $page.props.message,
        'alert-error': $page.props.error,
        'alert-warning': $page.props.warning
      }"
          @click="clearToast"
      >
        <span v-html="$page.props.message || $page.props.error || $page.props.warning"></span>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import {usePage} from "@inertiajs/vue3";
import {watch} from "vue";

const page = usePage();
const clearToast = () => {
  page.props.message = null;
  page.props.error = null;
  page.props.warning = null;
};
watch(
    () => page.props.message || page.props.error || page.props.warning,
    () => {
      if (page.props.message || page.props.error || page.props.warning) {
        setTimeout(() => clearToast(), 4000);
      }
    }
);
</script>
