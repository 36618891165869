<template>
  <div
      class="print:hidden hidden lg:flex container max-w-screen-1.5xl px-4 lg:px-8 mx-auto flex-row justify-between text-sm xl:text-base font-semibold">
    <div class="flex flex-row xl:gap-x-4 gap-x-2 xl:mr-4 mr-2">
      <MenuItem v-for="(item, key) in menu" :key="key" :item="item"/>
      <Link
          class="hover:text-secondary-500 border-b-white hover:border-b-secondary-500 border-b-2 pb-2"
          :href="trRoute('special-offers')">
        {{ __("Eripakkumised") }}
      </Link>
    </div>
    <div
        class="cursor-pointer dropdown"
        :class="{'dropdown-open': visible}"
        @mouseenter="showMenu"
        @click="toggleMenu"
        @mouseleave="hideMenu"
    >
      <div role="button" class="inline-block">
        {{ __("Klienditugi") }}
      </div>
      <ul class="dropdown-content p-2 shadow border border-gray-300 menu z-[2] bg-base-100 right-0 rounded w-52">
        <li>
          <a href="https://www.facebook.com/eluxkodutehnika/">
            Facebook
          </a>
        </li>
        <li>
          <a href="tel:003725024000">
            tel: 5024000
          </a>
        </li>
        <li>
          <a href="mailto:info@elux.ee">
            info@elux.ee
          </a>
        </li>
        <li>
          <Link :href="trRoute('news')">
            {{ __("Uudised") }}
          </Link>
        </li>
        <li>
          <Link :href="trRoute('contact')">
            {{ __("Kontakt") }}
          </Link>
        </li>
        <li></li>
        <li v-for="(title, url) in helpPages" :key="url">
          <Link

              class="item"
              :class="'page-' + url"
              :href="trRoute('page', { page: url })"
          >
            {{ title }}
          </Link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import MenuItem from "../components/MenuItem.vue";
import {__, trRoute} from "../utils";
import {Link, usePage} from "@inertiajs/vue3";
import {computed, ref} from "vue";

const page = usePage();
const menu = computed(() => page.props.menu);
const helpPages = computed(() => page.props.helpPages);
const visible = ref(false);
const toggleMenu = () => visible.value = !visible.value;
const hideMenu = () => visible.value = false;
const showMenu = () => visible.value = true;
</script>
