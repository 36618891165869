import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  id: "Layer_1",
  x: "0",
  y: "0",
  version: "1.1",
  viewBox: "0 0 264 94.6"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(".st0{fill:#243746}.st1{fill:#ff5100}")
      ])),
      _: 1
    })),
    _cache[1] || (_cache[1] = _createElementVNode("path", {
      d: "M32.9 77.1c2.5 2.4 5.9 3.6 10.3 3.6 2.7 0 5.3-.6 7.7-1.8 3-1.6 5.8-3.6 8.3-6 .3-.3.8-.5 1.2-.5s.8.1 1.2.2l9.9 4.3c.7.3 1 .8 1 1.4-.1.5-.4 1-.8 1.4-5 6.1-14.2 14.8-32.3 14.8-20 0-27.4-10-29.8-15.1-2.5-5.5-3.3-11.7-2.4-17.7.7-6.7 3.1-13.2 7-18.8 3.8-5.5 13-16 32.1-16S71.8 37 74 42.4s2.9 11.9 2 19.4c-.1.8-.3 1.7-.6 2.4-.3.4-.9.7-1.9.8H32c-1.2 0-1.9.7-2 2-.6 4.3.4 7.6 2.9 10.1m20-24q.9 0 1.8-.3.6-.6.6-1.5c.4-2.9-.3-5.7-1.9-8.2s-4.1-3.8-7.5-3.8c-3.5 0-6.8 1.3-9.2 3.7s-3.9 5.5-4.4 8.8c-.1.6.3 1.2.9 1.3h19.7",
      class: "st0"
    }, null, -1)),
    _cache[2] || (_cache[2] = _createElementVNode("path", {
      d: "M105.3 91.2c0 1-.5 2-1.2 2.7-.9.6-1.9.8-3 .8h-16c-.9.1-1.7-.1-2.4-.7-.5-.6-.6-1.5-.4-2.2l11.4-89C94 .9 94.8 0 96.4 0h18q.9 0 1.5.6c.4.5.5 1.2.4 1.8zM260.7 93.2c-.1.9-1.1 1.4-3 1.4h-18.8c-2.3 0-3.8-.8-4.6-2.3l-9.5-16.4c-.6-1.1-1.1-1.7-1.5-1.7s-1 .5-1.8 1.5l-13.6 16.6c-1.2 1.6-3.1 2.4-5.1 2.3h-12c-.8 0-1.7-.1-2.4-.5-.6-.3-.9-.9-.8-1.5.1-.9.9-2.2 2.4-3.8l23.1-26c.6-.5 1-1.2 1.2-1.9 0-.8-.2-1.6-.7-2.3L196.8 31c-.3-.5-.5-1.1-.4-1.7.1-.7.4-1.3 1-1.6.6-.4 1.4-.7 2.1-.6h18c1-.1 2 .1 2.9.5.7.4 1.3 1 1.6 1.8l8.1 14.5c.3.7.7 1 1 1s.8-.3 1.3-1l11.1-14.5q.9-1.05 2.1-1.8c.9-.4 1.9-.6 2.9-.5h13.3c1.6 0 2.4.5 2.2 1.4-.1.7-.5 1.4-1 1.9l-21.2 24.7c-.6.5-1 1.2-1.1 1.9 0 .8.3 1.6.7 2.3l18.1 30.4c.9 1.6 1.3 2.8 1.2 3.5M150.5 79.8c-1.8 0-3.6-.3-5.3-.9-.7-.2-1.4-.6-1.9-1.1-1.3-1.6-1.8-3.6-1.4-5.6l5.2-42.1c.2-.8.1-1.7-.3-2.4-.5-.5-1.3-.7-2-.6h-16.5c-2.4 0-3.7 1.1-4 3.4L119 73.7c-.8 6.5.4 11.6 3.5 15.3s7.6 5.6 13.3 5.6h25.8c5.4.1 10.7-1.9 14.7-5.6q6-5.55 7.2-15.3l5.3-43.2c.3-2.3-.8-3.4-3.1-3.4h-16.4c-.8-.1-1.6.2-2.2.6q-.75 1.05-.9 2.4L161 72.2c-.2 2.2-1.2 4.2-2.8 5.6-.7.5-1.4.9-2.2 1.1-1.8.6-3.6.9-5.5.9",
      class: "st1"
    }, null, -1)),
    _cache[3] || (_cache[3] = _createElementVNode("ellipse", {
      cx: "11.1",
      cy: "11.1",
      class: "st0",
      rx: "11.7",
      ry: "10.4",
      transform: "rotate(-43.241 11.115 11.054)"
    }, null, -1))
  ]))
}
export default { render: render }