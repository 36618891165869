import {createApp, h} from "vue";
import * as Sentry from "@sentry/vue";
import {resolvePageComponent} from "laravel-vite-plugin/inertia-helpers";
import {createPinia} from "pinia";
import {ZiggyVue} from "ziggy-js";
import {createInertiaApp} from "@inertiajs/vue3";
import {i18nVue} from "laravel-vue-i18n";
import AppLayout from "./AppLayout.vue";
import Feedbacky from "@ogzhnsfgl/feedbacky";
import request from "./request";
import {__, trRoute} from "./utils";

const feedbackyInstance = new Feedbacky({
    clientId: "elux",
    customSubmitHandler: (data) => {
        return request.post(trRoute("feedback"), data);
    },
    formFields: [
        {
            element: 'input',
            type: 'email',
            name: 'email',
            label: __('E-posti aadress'),
            placeholder: '',
            required: true,
            requiredErrorMessage: __('Palun sisesta oma e-posti aadress')
        },
        {
            element: 'textarea',
            name: 'message',
            label: __('Teade'),
            placeholder: '',
            required: true,
            requiredErrorMessage: __('Palun sisesta oma teade')
        }
    ],
    primaryColor: "#021f40",
    secondaryColor: "#ff5000",
    submitButtonText: __("Saada"),
    description: __("Kas sul on küsimusi seoses meie lehega?"),
    title: __("Anna tagasisidet"),
    successMessage: __("Tagasiside on saadetud"),
    errorMessage: __("Tagasiside saatmisel tekkis viga"),
    successButtonText: __("Sulge"),
    errorButtonText: __("Sulge aken")
});
feedbackyInstance.init();

createInertiaApp({
    title: (title) => `${title} - E-Lux`,
    resolve: async (name) => {
        const page = await resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        );
        page.default.layout = AppLayout;
        return page;
    },
    setup({el, App, props, plugin}) {
        const pinia = createPinia();
        return request.get('/json-files').then(res => {
            const app = createApp({render: () => h(App, props)})
                .use(plugin)
                .use(i18nVue, {
                    lang: props.initialPage.props.locale,
                    fallbackLang: "et",
                    fallbackMissingTranslations: true,
                    resolve: (lang) => res.data[lang]
                })
                .use(pinia)
                .use(ZiggyVue);
            if (import.meta.env.VITE_SENTRY_DSN) {
                Sentry.init({
                    app,
                    dsn: import.meta.env.VITE_SENTRY_DSN,
                    integrations: [
                        Sentry.browserTracingIntegration(),
                        Sentry.replayIntegration()
                    ],
                    sampleRate: 0.1,
                    tracesSampleRate: 0.01,
                    trackComponents: true,
                    replaysSessionSampleRate: 0.01,
                    replaysOnErrorSampleRate: 1.0
                });
            }
            app.mount(el);
            return app;
        });
    },
    progress: {color: "#e74306"}
});