<template>
  <div class="drawer-side z-50">
    <label for="sidebar" class="drawer-overlay" @click.prevent="toggleSidebar"></label>
    <ul class="menu p-0 w-[80vw] min-h-full bg-white">
      <li>
        <a href="#" @click="toggleSidebar">
          <XMarkIcon class="w-8 h-8"/>
        </a>
      </li>
      <LangMobile/>
      <template v-if="user && user.b2c_client">
        <li>
          <div class="btn btn-sm btn-ghost">
            {{ user.name }}
            <template v-if="user.b2c_client.client_type === 'company'"
            >, {{ user.b2c_client.name }}
            </template>
          </div>
        </li>
        <li class="px-4 py-2">
          <Link
              class="btn btn-sm"
              :href="trRoute('orders.index')"
              @click="toggleSidebar"
          >
            {{ __("Tellimused") }}
          </Link>
        </li>
        <li class="px-4 py-2">
          <Link
              class="btn btn-sm"
              :href="trRoute('settings')"
              @click="toggleSidebar"
          >
            {{ __("Seaded") }}
          </Link>
        </li>
        <li class="px-4 py-2">
          <Link
              id="logout-btn"
              class="btn btn-sm btn-outline btn-error mobile-logout"
              method="post"
              :href="trRoute('logout')"
              as="button"
              type="button"
          >
            {{ __("Välju") }}
          </Link>
        </li>
      </template>
      <template v-else>
        <li class="px-4 py-2">
          <Link class="btn btn-sm btn-primary" :href="trRoute('login')" @click="toggleSidebar">
            {{ __("Sisene") }}
          </Link>
        </li>
        <li class="px-4 py-2">
          <Link
              class="btn btn-sm btn-primary btn-outline"
              :href="trRoute('register')"
              @click="toggleSidebar"
          >
            {{ __("Registreeri") }}
          </Link>
        </li>
      </template>
      <RowAccordion
          v-for="(item, k) in menu"
          :key="k"
          :toggled="false"
          :title="item ? trField(item, 'title') : ''"
          :class="'c-' + (item ? item.id : k)"
      >
        <div
            v-for="(child, idx) in item.children"
            :key="idx"
            class="py-1"
        >
          <Link
              class="link"
              :href="trRoute('category', { category: trField(child, 'url') })"
              @click="toggleSidebar"
          >
            {{ trField(child, "title") }}
          </Link>
        </div>
      </RowAccordion>
      <li class="px-4 py-2">
        <Link
            class="py-1 px-0 text-base font-semibold"
            :href="trRoute('special-offers')"
            @click="toggleSidebar"
        >
          {{ __("Kampaania") }}
        </Link>
      </li>
      <RowAccordion :title="__('Klienditugi')">
        <div class="join join-vertical">
          <a class="join-item py-1" :href="B2B_URL">
            {{ __("Hulgikliendile") }}
          </a>
          <Link class="join-item py-1" :href="trRoute('news')" @click="toggleSidebar">
            {{ __("Uudised") }}
          </Link>
          <a href="https://www.facebook.com/eluxkodutehnika/" class="join-item py-1">
            Facebook
          </a>
          <Link
              v-for="(title, url) in helpPages"
              :key="url"
              class="join-item py-1"
              :href="trRoute('page', { page: url })"
              @click="toggleSidebar"
          >
            {{ title }}
          </Link>
        </div>
      </RowAccordion>
      <li class="menu-title font-normal">
        <div class="w-full prose prose-sm" v-html="footer">
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import {XMarkIcon} from "@heroicons/vue/24/solid";
import {__, trField, trRoute} from "../utils";
import {Link, usePage} from "@inertiajs/vue3";
import RowAccordion from "../components/RowAccordion.vue";
import {computed} from "vue";
import {useStore} from "../useStore";
import LangMobile from "../components/LangMobile.vue";

const store = useStore();
const {toggleSidebar} = store;
const page = usePage();
const menu = computed(() => page.props.menu);
const user = computed(() => page.props.user);
const footer = computed(() => page.props.footer);
const helpPages = computed(() => page.props.helpPages);
const B2B_URL = import.meta.env.VITE_B2B_URL;
</script>
