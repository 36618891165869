<template>
  <div class="prices text-left text-2xl font-bold p-0 m-0 leading-5" :class="classes">
    <template v-if="isDiscount">
      <div class="text-secondary-500">
        {{ priceFraction(priceVat, true) }}<small>{{ priceFraction(priceVat, false) }}</small>€
      </div>
    </template>
    <div v-else class="p-0 m-0 text-2xl font-bold leading-5 text-gray-900">
      {{ priceFraction(priceVat, true) }}<small>{{ priceFraction(priceVat, false) }}</small>€
    </div>
    <div v-if="isDiscount && !goodPrice" class="text-base text-primary-500">
      <span class="line-through">
        {{ priceFraction(priceDefaultVat, true) }}<small>{{ priceFraction(priceDefaultVat, false) }}</small>€
      </span>
      {{ __("Sääst") }} {{
        priceFraction(priceDefaultVat - priceVat, true)
      }}<small>{{ priceFraction(priceDefaultVat - priceVat, false) }}</small>€
    </div>
    <div v-else-if="installment(priceVat) && showInstallment" class="text-base text-primary-500 font-semibold">
      {{ __("al :price kuus", {price: loan(priceVat)}) }}
    </div>
  </div>
</template>

<script setup>
import {__, hasDiscount, installment, loan, priceFraction} from "../utils";
import {computed} from "vue";

const props = defineProps({
  showInstallment: {
    type: Boolean,
    default: true
  },
  classes: String,
  goodPrice: {
    type: [Number, Boolean],
    required: true
  },
  priceVat: {
    type: [String, Number],
    required: true
  },
  priceDefaultVat: {
    type: [String, Number],
    required: true
  },
  discountStart: String,
  discountEnd: String,
});
const isDiscount = computed(
    () =>
        props.goodPrice ||
        hasDiscount(
            props.priceVat,
            props.priceDefaultVat,
            props.discountStart,
            props.discountEnd
        )
);
</script>
