<template>
  <div v-if="mobile">
    <template v-if="count[0] === 0">
      <TarneSvg class="inline-block"/>
      {{ __("Tarne üle Eesti") }}
    </template>
    <template v-else-if="count[0] === 1">
      <JarelmaksSvg class="inline-block"/>
      {{ __("Paindlik ja soodne järelmaks") }}
    </template>
    <template v-else-if="count[0] === 2">
      <LisagarantiiSvg class="inline-block"/>
      {{ __("Turvatunne lisagarantiiga") }}
    </template>
    <template v-else-if="count[0] === 3">
      <SortimentSvg class="inline-block"/>
      {{ __("Valikus üle 5000 toote") }}
    </template>
  </div>
  <div v-else class="flex justify-between">
    <div>
      <TarneSvg class="inline-block"/>
      {{ __("Tarne üle Eesti") }}
    </div>
    <div>
      <JarelmaksSvg class="inline-block"/>
      {{ __("Paindlik ja soodne järelmaks") }}
    </div>
    <div>
      <LisagarantiiSvg class="inline-block"/>
      {{ __("Turvatunne lisagarantiiga") }}
    </div>
    <div>
      <SortimentSvg class="inline-block"/>
      {{ __("Valikus üle 5000 toote") }}
    </div>
  </div>
</template>

<script setup>

import {__} from "../utils.js";
import JarelmaksSvg from "../../images/arguments/jarelmaks.svg";
import LisagarantiiSvg from "../../images/arguments/lisagarantii.svg";
import TarneSvg from "../../images/arguments/tarne.svg";
import SortimentSvg from "../../images/arguments/sortiment.svg";
import {ref} from "vue";

defineProps({
  mobile: {
    type: Boolean,
    default: false
  }
});
const shuffle = (array) => {
  let currentIndex = array.length;
  while (currentIndex !== 0) {
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
};
const count = ref([0, 1, 2, 3]);
setInterval(() => {
  shuffle(count.value);
}, 5000);
</script>