<template>
  <li class="flex-row">
    <a
        v-for="locale in locales"
        :key="locale"
        class="inline-block w-fit"
        :class="{'font-bold': activeLang === locale}"
        :href="useRoute('home-' + locale)"
        @click.prevent="changeLang(locale)"
    >
      {{ langName(locale) }}&nbsp;
      <span
          class="!w-5 h-5 fi fis rounded-full border"
          :class="{
          'fi-ee': locale === 'et',
          'fi-ru': locale === 'ru',
          'fi-fi': locale === 'fi'
        }"></span>
    </a>
  </li>
</template>

<script setup>
import {onClickOutside} from "@vueuse/core";
import {getActiveLanguage} from "laravel-vue-i18n";
import {usePage} from "@inertiajs/vue3";
import {computed, ref} from "vue";
import {__, useRoute} from "../utils.js";

const target = ref(null);
onClickOutside(target, () => target.value.open = false);
const page = usePage();
const langName = (locale) => {
  switch (locale) {
    case "et":
      return __("Eesti");
    case "ru":
      return __("Русский");
    case "fi":
      return __("Suomi");
    default:
      return locale;
  }
};
const activeLang = computed(() => getActiveLanguage());
const locales = computed(() => page.props.locales);
const changeLang = (lang) => {
  let location;
  const currentRoute = useRoute();
  const currentRouteName = currentRoute.current();
  const add = "-" + lang;
  switch (currentRouteName) {
    case "news.show-et":
    case "news.show-ru": {
      const newsItem = page.props.newsItem;
      if (newsItem.alias) {
        location = useRoute("news-item" + add, {news: newsItem.alias});
      } else {
        location = useRoute("news" + add);
      }
      break;
    }
    case "page-et":
    case "page-ru": {
      const currentPage = page.props.page;
      if (currentPage.alias) {
        location = useRoute("page" + add, {page: currentPage.alias});
      } else {
        location = useRoute("home" + add);
      }
      break;
    }
    case "category-et":
      location = useRoute("category-ru", {
        ...currentRoute.params,
        category: page.props.category.url_ru
      });
      break;
    case "category-ru":
      location = useRoute("category-et", {
        ...currentRoute.params,
        category: useRoute.props.category.url
      });
      break;
    case "product-et":
      location = useRoute("product-ru", {
        ...currentRoute.params,
        product: page.props.product.url_ru
      });
      break;
    case "product-ru":
      location = useRoute("product-et", {
        ...currentRoute.params,
        product: page.props.product.url
      });
      break;
    default: {
      const routeName =
          activeLang.value === "et"
              ? currentRouteName.substring(0, currentRouteName.lastIndexOf("-et"))
              : currentRouteName.substring(0, currentRouteName.lastIndexOf("-ru"));
      location = useRoute(routeName + add, {...currentRoute.params});
    }
  }
  window.location = location;
};
</script>
