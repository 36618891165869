import {acceptHMRUpdate, defineStore} from "pinia";

export const useStore = defineStore("app", {
    state: () => ({
        sidebar: false,
        filters: false
    }),
    actions: {
        toggleSidebar() {
            this.sidebar = !this.sidebar;
        },
        toggleFilters() {
            this.filters = !this.filters;
        }
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot));
}
